var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.subSections, function(ref, index) {
        var _obj

        var slug = ref.slug
        var component = ref.component
        var attribute = ref.attribute
        var hasEntities = ref.hasEntities
        return [
          _c(
            "div",
            { key: index, attrs: { id: slug } },
            [
              _c(
                component,
                _vm._b(
                  {
                    ref: slug,
                    refInFor: true,
                    tag: "component",
                    attrs: {
                      application_id: _vm.application.id,
                      applicant_id: _vm.applicant.id,
                      sections: _vm.applicant.sections,
                      meta: _vm.applicant.meta
                    },
                    on: {
                      update: function($event) {
                        return _vm.update(attribute, $event)
                      }
                    }
                  },
                  "component",
                  ((_obj = {}),
                  (_obj[hasEntities ? "entities" : attribute] =
                    _vm.applicant[attribute]),
                  _obj),
                  false
                )
              )
            ],
            1
          )
        ]
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { "offset-md": "2" } },
            [
              _c(
                "v-btn",
                { staticClass: "my-2 mr-4", on: { click: _vm.prevStep } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1 grey--text text--darken-2" },
                    [_vm._v("mdi-chevron-left-circle")]
                  ),
                  _vm._v(_vm._s(_vm.$t("applications.back")))
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "my-2",
                  attrs: { color: "secondary" },
                  on: { click: _vm.nextStep }
                },
                [
                  _vm._v(_vm._s(_vm.$t("applications.continue")) + " "),
                  _c(
                    "v-icon",
                    { staticClass: "ml-1 secondary--text text--lighten-2" },
                    [_vm._v("mdi-chevron-right-circle")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: "error",
            "multi-line": "",
            value: _vm.snackbar,
            bottom: "",
            timeout: 3000
          },
          on: {
            input: function($event) {
              _vm.errors = []
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center mb-1" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("applications.errors-with-form")))
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", text: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.errors = []
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "ul",
            _vm._l(_vm.errors, function(error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error) + " ")
              ])
            }),
            0
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }