<template>
    <div>
        <template
            v-for="(
                { slug, component, attribute, hasEntities }, index
            ) in subSections"
        >
            <div :id="slug" :key="index">
                <component
                    :is="component"
                    :ref="slug"
                    v-bind="{
                        [hasEntities ? 'entities' : attribute]:
                            applicant[attribute],
                    }"
                    :application_id="application.id"
                    :applicant_id="applicant.id"
                    :sections="applicant.sections"
                    :meta="applicant.meta"
                    @update="update(attribute, $event)"
                ></component>
            </div>
        </template>
        <v-row>
            <v-col offset-md="2">
                <v-btn class="my-2 mr-4" @click="prevStep"
                    ><v-icon class="mr-1 grey--text text--darken-2"
                        >mdi-chevron-left-circle</v-icon
                    >{{ $t("applications.back") }}</v-btn
                >
                <v-btn color="secondary" class="my-2" @click="nextStep"
                    >{{ $t("applications.continue") }}
                    <v-icon class="ml-1 secondary--text text--lighten-2"
                        >mdi-chevron-right-circle</v-icon
                    >
                </v-btn>
            </v-col>
        </v-row>

        <v-snackbar
            color="error"
            multi-line
            :value="snackbar"
            @input="errors = []"
            bottom
            :timeout="3000"
        >
            <div class="d-flex align-center mb-1">
                <span>{{ $t("applications.errors-with-form") }}</span>
                <v-spacer></v-spacer>
                <v-btn small text @click.stop="errors = []"
                    ><v-icon small>mdi-close</v-icon></v-btn
                >
            </div>
            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </v-snackbar>
    </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
    name: "applicant-apply",
    components: {
        PersonalInfo: () => import("./PersonalInfo"),
        RentalHistory: () => import("./RentalHistory"),
        EmploymentHistory: () => import("./EmploymentHistory"),
        ApplicantOtherIncomes: () => import("./ApplicantOtherIncomes"),
        ApplicantDependents: () => import("./ApplicantDependents"),
        ApplicantPets: () => import("./ApplicantPets"),
        ApplicantVehicles: () => import("./ApplicantVehicles.vue"),
        ApplicantDisclosures: () => import("./ApplicantDisclosures"),
        ApplicantEmergencyContacts: () =>
            import("./ApplicantEmergencyContacts"),
    },
    props: {
        step: {
            type: [Number, String],
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            errors: [],
            subSections: this.buildSubSections(),
        };
    },
    watch: {},
    computed: {
        snackbar() {
            return this.errors.length > 0;
        },
    },
    methods: {
        ...mapActions("Applications", ["updateWithoutReload"]),
        /**
         * Builds the apply subsections from the application sections. Allows dynamic on/off of sections
         *
         * @return void
         */
        buildSubSections() {
            return this.$config("applications.applySubSections")
                .filter((section) => {
                    return (
                        this.$_.get(
                            this.applicant,
                            `sections.${section.slug}`,
                            false
                        ) && section.enabled
                    );
                })
                .map((section) => {
                    section.title = this.$t(`applications.${section.slug}`);
                    return section;
                });
        },
        /**
         * Validate the apply step calling each section and building the errors array
         *
         * @return void
         */
        validate() {
            this.errors = [];
            // eslint-disable-next-line no-unused-vars
            Object.entries(this.$refs).forEach(([key, section]) => {
                if (section.length > 0) {
                    if (!section[0].validate()) {
                        this.errors.push(
                            this.$t("applications.section-errors", {
                                section: section[0].name,
                            })
                        );
                    }
                }
            });
        },
        /**
         * Single update call to update the backend database supporting save where left off
         *
         * @param {string} attribute - indicates the attribute we are saving
         * @param {Object | Array} data  - data to save for the attribute
         * @return void
         */
        update(attribute, data) {
            // console.log(
            //     "Apply update called for attribute %s and data = %o",
            //     attribute,
            //     data
            // );
            this.updateWithoutReload({
                [attribute]: data,
            });
        },
        prevStep() {
            this.$emit("previous", parseInt(this.step) - 1);
        },
        nextStep() {
            this.validate();
            if (this.errors.length == 0) {
                this.$emit("next", parseInt(this.step) + 1);
            }
        },
    },
};
</script>

<style scoped>
.theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused
    > .v-input__control
    > .v-input__slot {
    background: #929292 !important;
}
</style>